import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { GreenPoleAvatarXL, Icon } from 'lib/components';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isUserRole } from 'utils/token';
import userRoles from 'utils/userRoles';

import './SideBar.scss';

export const SideBar = ({ user }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [modulesMenuActive, toggleModulesMenuActive] = useState(false);

  const location = useLocation();

  const currentRoute = () => {
    setActiveMenu(location.pathname.split('/')[1]);
  };

  const toggleModulesMenu = (e) => {
    e.preventDefault();
    toggleModulesMenuActive(!modulesMenuActive);
  };

  const hideModulesMenu = () => {
    toggleModulesMenuActive(false);
  };

  const logout = () => {
    localStorage.removeItem('greenpole_redux_state');
    window.location.replace(
      `${
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_FRONTENDURL
          : 'http://localhost:3000/login'
      }`,
    );
  };

  useEffect(() => {
    currentRoute();
  }, []);

  return (
    <aside className="sidebar">
      <ul className="sidebar-nav">
        <li className="nav-item">
          <Link
            to="/modules/home"
            className={`${activeMenu === 'home' ? 'active' : ''}`}
          >
            <span className="nav-body">
              <span className="link-icon">
                <Icon name="Dashboard" />
              </span>
              <span className="link-text">Dashboard</span>
            </span>
          </Link>
        </li>
        <li className="nav-item">
          <a
            href=""
            className={`${
              activeMenu !== 'dashboard' &&
              activeMenu !== 'admin-settings' &&
              activeMenu !== 'management'
                ? 'active'
                : ''
            }`}
            onClick={(e) => toggleModulesMenu(e)}
          >
            <span className="nav-body">
              <span className="link-icon">
                <Icon name="List" />
              </span>
              <span className="link-text">Modules</span>
              <span className="dropdown-icon">
                {modulesMenuActive ? <UpOutlined /> : <DownOutlined />}
              </span>
            </span>
          </a>

          {modulesMenuActive && (
            <div className="modules-menu" onBlur={() => hideModulesMenu()}>
              {(isUserRole(userRoles.MUTUAL_FUND_ADMIN) ||
                isUserRole(userRoles.SUPER_ADMIN)) && (
                <Link to="/modules/mutualfund/view-mutual-fund">
                  Mutual Fund
                </Link>
              )}
              {(isUserRole(userRoles.DIVIDEND_ADMIN) ||
                isUserRole(userRoles.CLIENT_COMPANY_ADMIN) ||
                isUserRole(userRoles.HOLDER_ADMIN) ||
                isUserRole(userRoles.CERTIFICATE_ADMIN) ||
                isUserRole(userRoles.TRANSACTION_ADMIN) ||
                isUserRole(userRoles.AGENT_ADMIN) ||
                isUserRole(userRoles.SUPER_ADMIN)) && (
                <Link to="/modules/bond">Bond</Link>
              )}
              {(isUserRole(userRoles.DIVIDEND_ADMIN) ||
                isUserRole(userRoles.CLIENT_COMPANY_ADMIN) ||
                isUserRole(userRoles.HOLDER_ADMIN) ||
                isUserRole(userRoles.CERTIFICATE_ADMIN) ||
                isUserRole(userRoles.TRANSACTION_ADMIN) ||
                isUserRole(userRoles.AGENT_ADMIN) ||
                isUserRole(userRoles.SUPER_ADMIN)) && (
                <Link to="/modules/Equity">Equity</Link>
              )}
              {/* {(isUserRole(userRoles.OFFER_ADMIN) ||
                isUserRole(userRoles.SUPER_ADMIN)) &&
                JSON.parse(process.env.REACT_APP_OFFER_MODULE_DISPLAY) && (
                  <div>
                    <div
                      className="offer-parent-menu-container"
                      onClick={handleToggleOfferMenu}>
                      <span>Offer</span>
                      <CaretDownOutlined className="offer-dropdown-icon" />
                    </div>

                    {isOfferModuleMenuActive && (
                      <div className="offer-sub-menu-container">
                        {offerTypes
                          .filter((o) => o.name != 'offer')
                          .map((type, index) => (
                            <Link
                              key={index}
                              className="sub-menu-item"
                              onClick={() => handleSelectedOfferType(type)}
                              to={`/modules/Equity/${offerUrl?.MANAGE_OFFER}?id=${type.value}&type=${type.key}`}>
                              {type.name}
                            </Link>
                          ))}
                      </div>
                    )}
                  </div>
                )} */}

              <Link to="/modules/management">Manage Account</Link>

              {isUserRole(userRoles.SUPER_ADMIN) && (
                <Link to="/modules/admin-settings/home">Admin Settings</Link>
              )}
            </div>
          )}
        </li>

        <li className="nav-item">
          <Link to="#" onClick={() => logout()}>
            <GreenPoleAvatarXL
              src={user && user.pic}
              size="sm"
              alt={user && user.name}
            />
            <span className="link-text">Logout</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
};
