import React from 'react';
import { Table, Pagination, Row } from 'antd';
import { TableLoader, Loader } from 'lib/components';
import InfiniteScroll from 'react-infinite-scroll-component';

const TableAndPagination = ({
  columns,
  dataSource,
  pageSize,
  onShowSizeChange,
  current,
  total,
  onChange,
  pageSizeOptions,
  showTotal,
  scrollValueX = 1000,
  scrollValueY,
  rowSelection,
  loading = false,
  paginationLoading = false,
  onBack,
  showPagination = true,
  rowKey = 'id',
  hasMore,
  showSn = true,
  isDisableInfiniteScroll = false,
}) => {
  const generateTableColumn = () => {
    const columnsToRender = [];
    const snColumn = [
      {
        title: 'S N',
        key: 'sno',
        width: '60px',
        align: 'center',
        render: (text, object, index) => index + 1,
      },
    ];
    if (showSn) {
      columnsToRender.push(...[...snColumn, ...columns]);
    } else {
      columnsToRender.push(...columns);
    }

    return columnsToRender;
  };

  const renderTable = () => {
    if (loading) {
      return (
        <div>
          <TableLoader />
        </div>
      );
    } else if (!loading) {
      if (isDisableInfiniteScroll) {
        return (
          <>
            <Table
              className="table"
              bordered
              columns={generateTableColumn()}
              dataSource={dataSource}
              rowKey={rowKey}
              size="middle"
              pagination={false}
              scroll={{ x: scrollValueX, y: 400 }}
              rowSelection={rowSelection}
              loading={loading}
            />
            <Row
              style={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Pagination
                total={total}
                onChange={onChange}
                pageSizeOptions={[5, 10, 30, 50, 100, 200, 500]}
                responsive
                current={current}
              />
            </Row>
          </>
        );
      } else {
        return (
          <InfiniteScroll
            dataLength={dataSource?.length}
            next={onChange}
            hasMore={hasMore}
            // loader={<Loader />}
          >
            <Table
              className="table"
              bordered
              columns={generateTableColumn()}
              dataSource={dataSource}
              rowKey={rowKey}
              size="middle"
              pagination={false}
              scroll={{ x: scrollValueX, y: 400 }}
              rowSelection={rowSelection}
              loading={loading}
            />
            {paginationLoading ? (
              <Loader />
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '50px',
                }}
              >
                {hasMore && (
                  <button
                    // className="btn-primary"
                    style={{
                      marginTop: '1rem',
                      borderRadius: '3px',
                      padding: '0 2rem',
                      border: '1px solid #299f9e',
                    }}
                    onClick={onChange}
                  >
                    Load More
                  </button>
                )}
              </div>
            )}
          </InfiniteScroll>
        );
      }
    }
  };
  return <div>{renderTable()}</div>;
};

export default TableAndPagination;
