import React from 'react';
import { Modal, Result, Button, Row, Col } from 'antd';
import warningIcon from 'lib/assets/icons/warning.svg';

import './ConfirmationModal.scss';

export const ConfirmationModal = ({
  modalVisible,
  title,
  subTitle,
  onClick,
  buttonText,
  cancelText,
  handleCancel,
  loader,
}) => {
  return (
    <Modal
      id="confirmation_modal"
      open={modalVisible}
      footer={null}
      onCancel={handleCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Result
        className="confirmation_result"
        icon={
          <img
            alt="create account success icon"
            src={warningIcon}
            style={{ height: '100px', width: '100px' }}
          />
        }
        title={title}
        subTitle={subTitle}
        extra={
          <Row gutter={[16, 16]} className="confirmation-row-buttons">
            <Col>
              <Button
                onClick={onClick}
                loading={loader}
                disabled={loader}
                className="confirmation-modal-proceed-btn"
              >
                {buttonText}
              </Button>
            </Col>

            <Col>
              <Button
                onClick={handleCancel}
                className="confirmation-modal-cancel-btn"
              >
                {cancelText}
              </Button>
            </Col>
          </Row>
        }
      />
    </Modal>
  );
};

export default ConfirmationModal;
