import React from 'react';
import logo from '../../assets/images/greenPole.png';

import './Logo.scss';

export const GreenPoleLogo = () => {
  return (
    <span className="logo">
      <img src={logo} width={150} height={50} />
      <span className="divider" />
      {process.env.REACT_APP_TENANT_LOGO && (
        <img
          src={process.env.REACT_APP_TENANT_LOGO}
          alt=""
          className="app-brand"
        />
      )}
    </span>
  );
};
