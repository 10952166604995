import Http from '../_util/httpClient';

export const getAllClientCompany = () => {
  return Http.get('/v2/client-company');
};

export const getBanks = () => {
  return Http.get('/v2/banks');
};

export const createCouponType = (data) => {
  return Http.post('/v2/coupon-type', data);
};

export const getCouponType = () => {
  return Http.get('/v2/coupon-type');
};

export const getSingleCouponType = (id) => {
  return Http.get(`/v2/coupon-type/${id}`);
};

export const editCouponType = (data, id) => {
  return Http.put(`/v2/coupon-type/${id}`, data);
};

export const createDividendType = (data) => {
  return Http.post('/v2/dividend/type', data);
};

export const getDividendType = (offset, limit) => {
  return Http.get(`/v2/dividend/type?offset=${offset}&limit=${limit}`);
};

export const getAllDividendType = (offset, limit) => {
  return Http.get('/v2/dividend/type/noPagination');
};

export const getSingleDividendType = (id) => {
  return Http.get(`/v2/dividend/type/${id}`);
};

export const crateQualifiedShareholders = (id) => {
  return Http.post(
    `/v2/holders/dividends/create-dividend-qualify-holders/${id}`,
  );
};

export const getShareholderList = (params, offset, limit) => {
  return Http.get(
    `/v2/holders/dividends/view-dividend-qualify-holders?${params}&offset=${offset}&limit=${limit}`,
  );
};

export const dividendPayoutList = (id, params, offset, limit) => {
  return Http.get(
    `/v2/dividend/${id}/payouts?${params}&offset=${offset}&limit=${limit}`,
  );
};

export const getUnPaidDividend = (params, offset, limit) => {
  return Http.get(
    `/v2/dividend/view-all-unissued?${params}&offset=${offset}&limit=${limit}`,
  );
};

export const editDividendType = (data, id) => {
  return Http.put(`/v2/dividend/type/${id}`, data);
};

export const setupDividend = (data) => {
  return Http.post('/v2/dividend', data);
};

export const annotateDividend = (data) => {
  return Http.post('/v2/holders/dividends/annotation', data);
};

export const editDividendSetup = (data, id) => {
  data.id = id;
  return Http.put('/v2/dividend', data);
};

export const querySetupDividend = (params, offset, limit) => {
  const query = `/v2/dividend?${params}&index=${offset}&limit=${limit}`;
  return Http.get(query);
};

export const querySetupDividendWithStatus = (offset, limit) => {
  const query = `/v2/dividend?index=${offset}&limit=${limit}`;
  return Http.get(query);
};

export const queryDividendRecord = (params, offset, limit) => {
  return Http.get(
    `/v2/holders/dividends?${params}&index=${offset}&limit=${limit}`,
  );
};

export const downloadQualifiedHolders = async (params, fileFormat) => {
  const url = `/v2/dividend-report/full-beneficiary?${params}&fileFormat=${fileFormat}`;
  return await Http.get(url);
};

export const downloadQualifiedHoldersWithPhoneNumber = async (
  params,
  fileFormat,
) => {
  const url = `/v2/dividend-report/full-beneficiary?${params}&fileFormat=${fileFormat}&beneficiaryType=with_phone_email`;
  return await Http.get(url);
};

export const downloadDeclaredEmandated = async (params, fileFormat) => {
  const url = `/v2/dividend-report/declared-e-mandated?${params}&fileFormat=${fileFormat}`;
  return await Http.get(url);
};

export const downloadWitholdingTaxReport = async (params, fileFormat) => {
  const url = `/v2/dividend-report/withholding-tax?${params}&fileFormat=${fileFormat}`;
  return await Http.get(url);
};

export const downloadWitholdingTaxSummary = async (dividendId, fileFormat) => {
  const url = `/v2/dividend-report/withholding-tax-qualified/${dividendId}?fileFormat=${fileFormat}`;
  return await Http.get(url);
};

export const getSingleDividendSetup = (id) => {
  return Http.get(`/v2/dividend/${id}`);
};

export const searchClientCompany = (offset, limit, query) => {
  return Http.get(
    `/dividend/client-company?offset=${offset}&limit=${limit}&search=${query}`,
  );
};

export const cancelDividend = (id, cancellationReason) => {
  return Http.post('/v2/dividend/cancel', {
    id,
    cancellationReason,
  });
};

export const viewCancelledDividend = (offset, limit) => {
  return Http.get(`/v2/dividend/cancel?offset=${offset}&limit=${limit}`);
};

export const reverseCancelDividend = (id, cancellationReversalReason) => {
  return Http.post(
    `/v2/dividend/cancel/reverse
  `,
    {
      id,
      cancellationReversalReason,
    },
  );
};

export const initiatePayment = (dividendDeclaredId) => {
  return Http.post(`/v2/dividend/${dividendDeclaredId}/payments`);
};

export const reIssuePayment = (data, sendEmail) => {
  return Http.post(
    `/v2/holders/dividends/reissue?sendEmail=${sendEmail}`,
    data,
  );
};

export const updateStatus = (data) => {
  return Http.patch(`/v2/holders/update-status`, data);
};

export const statusBar = (id) => {
  return Http.patch(`/v2/holders/mark_as_status_update/${id}`);
};

export const viewPayment = (batchId) => {
  return Http.get(`/v2/dividend/${batchId}/payment-records`);
};

export const viewEligiblePayment = (batchId, dividendId, offset, limit) => {
  return Http.get(
    `/v2/dividend/${dividendId}/payment-records/eligible?batchId=${batchId}&index=${offset}&limit=${limit}`,
  );
};

export const disbursePayment = (batchId) => {
  return Http.post(`/v2/payment/${batchId}/disburse`);
};

export const markAsPaid = (batchId) => {
  return Http.post(`/v2/payment/batch/${batchId}/mark-as-paid`);
};

export const uploadFailedPayment = (data) => {
  return Http.post(`/v2/payment/upload-failed`, data);
};

export const sendEmail = (batchId) => {
  return Http.post(`/v2/dividend/payout/reminder-email?batchId=${batchId}`);
};

export const declareDividend = (dividendDeclaredId) => {
  return Http.post(`/v2/dividend/${dividendDeclaredId}/declare`);
};

export const printDeclaredDividend = () => {
  return Http.get('/v2/dividend/dividend-declared/print');
};

export const approveDividend = (id) => {
  return Http.put(`/v2/dividend/${id}/approved`);
};

export const rejectDividend = (id) => {
  return Http.put(`/v2/dividend/${id}/rejected`);
};

export const createSnapshot = (id, isRefresh) => {
  return Http.get(`/v2/dividend/${id}/snapshot?isRefresh=${isRefresh}`);
};

export const viewSnapshot = (dividendId, params, offset, limit) => {
  return Http.get(
    `/v2/dividend/${dividendId}/snapshot/details?categoryType=${params}&index=${offset}&limit=${limit}`,
  );
};

export const downloadSnapshot = async (dividendId, params, fileFormat) => {
  const url = `/v2/dividend-report/snapshot/${dividendId}?type=${params}&fileFormat=${fileFormat}`;
  return await Http.get(url);
};

export const downloadE_Replacement = async (params, fileFormat) => {
  const url = `/v2/dividend-report/e_replacement?${params}&fileFormat=${fileFormat}`;
  return await Http.get(url, {
    responseType: 'blob',
  });
};

export const clientCompanyLastDividendDeclaredPayout = () => {
  return Http.get('/v2/dividend/client-company-last-dividend-declared-payout');
};

export const globalClientCompanyDividendPayout = () => {
  return Http.get('/v2/dividend/global-client-company-dividend-payout');
};

export const globalClientCompanyDividendPayoutId = (id) => {
  return Http.get(`/v2/dividend/global-client-company-dividend-payout/${id}`);
};

export const getDividendSnapshotCategories = (id, category) => {
  return Http.get(`/v2/holders/dividends/${id}/${category}`);
};

export const globalDividendPayout = () => {
  return Http.get('/v2/dividend/payout');
};

export const clientCompanyDividendSummary = (clientCompanyId) => {
  return Http.get(`/v2/client-company/${clientCompanyId}/payment-request`);
};

export const paymentRequest = (params, type, offset, limit) => {
  return Http.get(
    `/v2/payment/requests/${type}?${params}&index=${offset}&limit=${limit}`,
  );
};

export const failedPaymentRequest = (dividendId, params, offset, limit) => {
  return Http.get(
    `/v2/dividend/${dividendId}/failed-payments?${params}&offset=${offset}&limit=${limit}`,
  );
};

export const querySourceAccount = (params, offset, limit) => {
  return Http.get(
    `/v2/holders/unpaid?${params}&offset=${offset}&limit=${limit}`,
  );
};

export const createSupplementary = (data) => {
  return Http.post('/v2/dividend/supplementary-dividend', data);
};

export const getSupplementaryDividend = (params, offset, limit) => {
  return Http.get(
    `/v2/dividend/supplementary-dividend?${params}&index=${offset}&limit=${limit}`,
  );
};

export const initiateSupplementaryPayment = (dividendId) => {
  return Http.post(`/v2/dividend/${dividendId}/initiate-supplementary-payment`);
};

export const uploadBulkReissue = (payload, formData) => {
  return Http.post(
    `/v2/dividend/reissue/warrant-numbers/upload?bankName=${payload?.bankName}&accountNumber=${payload?.accountNumber}&paymentDate=${payload?.paymentDate}`,
    formData,
  );
};

export const uploadFinanceStatus = (data) => {
  return Http.post('/v2/finance/payments/upload', data);
};

export const getFinancePayments = (searchQuery) => {
  const params = new URLSearchParams();

  if (searchQuery?.page !== undefined && searchQuery?.page !== null) {
    params.append('page', searchQuery?.page);
  }

  if (searchQuery?.size !== undefined && searchQuery?.size !== null) {
    params.append('size', searchQuery?.size);
  }

  if (searchQuery?.uniqueId) {
    params.append('uniqueId', searchQuery?.uniqueId);
  }

  if (searchQuery?.clientCompanyId) {
    params.append('clientCompanyId', searchQuery?.clientCompanyId);
  }

  return Http.get(`/v2/finance/payments?${params.toString()}`);
};
